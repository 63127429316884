
export const environment = {
  production: false,
  apiBaseUrl: "https://iselltestapi.technoapps.net/",
  googleAPIUrl: "https://maps.googleapis.com/maps/api/geocode/json",
  googleAPIKey: "AIzaSyCHRCMw0jsO41118g_TK52mbBB7Ww2kPeE",
  fileURL: "https://iselltestapi.technoapps.net/api/Images/",
  filesURL: "https://iselltestapi.technoapps.net/Files/"
};

