// import { Injectable } from '@angular/core';
// import { HttpClient } from '@angular/common/http';
// import { Observable } from 'rxjs';
// import { environment } from '../../environments/environment';
// import { AuthModel } from '../Models/AuthModel';
// import { LoginModel } from '../Models/LoginModel';
// import { ForgetPasswordModel } from '../Models/forget-password-model';
// import { ResetPasswordModel } from '../Models/reset-password-model';

// @Injectable({
//   providedIn: 'root'
// })
// export class AuthenticationService {
//   constructor(private http: HttpClient) { }
//   lang: string;
//   public setLang(lng: string) {
//     this.lang = lng;
//   }

//   login(loginModel: LoginModel): Observable<AuthModel> {
//     let url: string = environment.apiBaseUrl + "cms/api/Authentication/Login";

//     if (!this.lang)
//       this.lang = 'en';

//     return this.http.post<AuthModel>(url, loginModel, {
//       headers: {
//         'lang': this.lang
//       }
//     });
//   }

//   saveAurhDate(model: AuthModel) {
//     localStorage.setItem("token", JSON.stringify(model))
//   }

//   isAuthenticated(): boolean {
//     let auth: AuthModel = (JSON.parse(localStorage.getItem("token")) as AuthModel);
//     if (auth == null)
//       return false;

//     if (auth.expireOn <= new Date()) {
//       this.logOut();
//       return false;
//     }
//     return true;
//   }

//   isVerified(): boolean {
//     let auth: AuthModel = (JSON.parse(localStorage.getItem("token")) as AuthModel);
//     if (!auth.isVerified && auth.userType == 2)
//       return false;
//     else
//       return true;
//   }

//   getAuthDate(): AuthModel | null {
//     let auth: AuthModel = (JSON.parse(localStorage.getItem("token")) as AuthModel);
//     return auth;
//   }

//   logOut() {
//     localStorage.removeItem("token");
//   }

//   authKeyExists(key: string): boolean {
//     let permissions: string[] = JSON.parse(localStorage.getItem("token"))["permission"] as string[];
//     if (!permissions)
//       return false;
//     return (JSON.parse(localStorage.getItem("token"))["permission"] as string[]).includes(key);
//   }


//   forgotPassword(model: ForgetPasswordModel): Observable<any> {
//     let url: string = environment.apiBaseUrl + "api/Authentication/ForgotPassword";
//     return this.http.post(url, model);
//   }

//   resetPassword(model: ResetPasswordModel): Observable<any> {
//     let url: string = environment.apiBaseUrl + "api/Authentication/ResetPassword";
//     return this.http.post(url, model);
//   }


// }


import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthModel } from '../Models/AuthModel';
import { LoginModel } from '../Models/LoginModel';

import { SelectItem } from 'primeng/api';
import { Console } from 'console';
import { UserModel } from '../Models/Membership/UserModel';
import { AccountModel, LightUserRolesDTO } from '../Models/AccountModel';
import { ForgotPasswordModel } from '../Models/ForgetPasswordModel';
import { ResetPasswordModel } from '../Models/reset-password-model';
import { VerifyModel } from '../Models/VerifyModel';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  urlPart = environment.apiBaseUrl + "cms/api/Authentication";
  tokeKeyName = "token";
  
  constructor(private http: HttpClient) { }

  lang: string;
  private Account: AccountModel;
  private loginModel: LoginModel;


  public setLang(lng: string) {
    this.lang = lng;
  }


  public setAccount(account: AccountModel) {
    this.Account = account;
    localStorage.setItem("accounts", JSON.stringify(account))

  }

  public setTempLoginData(login: LoginModel) {
    this.loginModel = login;
  }

  public getAccount(): AccountModel {
    this.Account = (JSON.parse(localStorage.getItem("accounts")) as AccountModel);
    return this.Account;
  }

  public getTempLoginData(): LoginModel {
    return this.loginModel;
  }

  login(loginModel: LoginModel): Observable<AuthModel> {
    let url: string = this.urlPart + "/login";

    if (!this.lang)
      this.lang = 'en';

    return this.http.post<AuthModel>(url, loginModel, {
      headers: {
        'lang': this.lang
      }
    });
  }

  switchAccount(accountId: number, organizationId: number): Observable<AuthModel> {
    let url: string = this.urlPart + "/switchAccount/" + accountId+"/"+organizationId;

    if (!this.lang)
      this.lang = 'en';

    let requestHeader: HttpHeaders;
    requestHeader = new HttpHeaders(
      {
        'Authorization': `bearer ${this.getAuthDate().token}`,
        'Lang': this.lang
      });
    return this.http.get<AuthModel>(url, {
      headers: requestHeader
    });
  }

  private GetOrganizationPagesACL(orgType: number) {
let url: string = this.urlPart + "/GetOrganizationPagesACL/" + orgType;
  return  this.http.get<string[]>(url);
  }
  getUserOrganizations(lang: string, verifyModel: VerifyModel): Observable<SelectItem[]> {
    let url: string = this.urlPart + "/";
    return this.http.post<SelectItem[]>(url + `UserOrganizations/${lang}`, verifyModel);

  }

  getPreLoginUserRoles(lang: string,sendOTP:boolean, verifyModel: VerifyModel): Observable<AccountModel> {
    let url: string = this.urlPart + "/";
    return this.http.post<AccountModel>(url + `PreLoginUserRolesCms/${lang}?sendOTP=${sendOTP}`, verifyModel);

  }
//
validOTP(code:string, emailOrPhoneNumber:string) {
  let url: string = this.urlPart + "/";
  return this.http.get(url + `validOTP/${code}/${emailOrPhoneNumber}`);

}
  saveAurhDate(model: AuthModel) {
    localStorage.setItem(this.tokeKeyName, JSON.stringify(model))
   return  this.GetOrganizationPagesACL(this.getOrganizationType());
  }

  isAuthenticated(): boolean {
    let auth: AuthModel = (JSON.parse(localStorage.getItem(this.tokeKeyName)) as AuthModel);

    if (auth == null)
      return false;

    if (auth.expireOn <= new Date()) {
      this.logOut();
      return false;
    }

    return true;
  }

  getAuthDate(): AuthModel | null {
    let auth: AuthModel = (JSON.parse(localStorage.getItem(this.tokeKeyName)) as AuthModel);
    return auth;
  }

  // getIsAdmin(): boolean {
  //   let auth: AuthModel = (JSON.parse(localStorage.getItem(this.tokeKeyName)) as AuthModel);
  //   return auth.isAdmin;
  // }
  getUserType(): number {
    let auth: AuthModel = (JSON.parse(localStorage.getItem(this.tokeKeyName)) as AuthModel);
    return auth.userType
  }
  getOrganizationType(): number {
    let auth: AuthModel = (JSON.parse(localStorage.getItem(this.tokeKeyName)) as AuthModel);
    // console.log(auth.organizationTypeId);
    return auth.organizationTypeId;
  }
  getOrganizationId(): number {
    let auth: AuthModel = (JSON.parse(localStorage.getItem(this.tokeKeyName)) as AuthModel);
    return auth.organizationId;
  }
  getBranchId(): number {
    let auth: AuthModel = (JSON.parse(localStorage.getItem(this.tokeKeyName)) as AuthModel);
    return auth.branchId;
  }
  getOtherAccounts(): LightUserRolesDTO[] {
    let account = this.getAccount();
    let currentAccountId = this.getCurrentAccountId();
    account.roles.forEach(element => {
      let index = element.organizationRoleBranches.findIndex(b => b.id == currentAccountId);
      if (index != -1) {
        element.organizationRoleBranches.splice(index, 1);
        if (!element.organizationRoleBranches.length)
          account.roles.splice(account.roles.indexOf(element), 1);
        return;
      }

    });
    return account.roles;//.filter(a=> a.organizationRoleBranches.findIndex(b=>b.id== currentAccountId)== -1);

  }

  getCurrentAccountId(): number {
    let auth: AuthModel = (JSON.parse(localStorage.getItem(this.tokeKeyName)) as AuthModel);
    return auth.userRoleId;
  }

  logOut() {
    localStorage.removeItem(this.tokeKeyName);
    localStorage.removeItem("accounts");
    // localStorage.removeItem("orgPages");

  }

  authKeyExists(key: string): boolean {
    let permissions: string[] = JSON.parse(localStorage.getItem(this.tokeKeyName))["permission"] as string[];

    if (!permissions)
      return false;
    let x = (permissions).includes(key);
    return x;
  }


  forgotPassword(model: ForgotPasswordModel): Observable<ForgotPasswordModel> {
    let url: string = this.urlPart + "/ForgotPassword";
    // console.log(model)
    if (!this.lang)
      this.lang = 'ar';
    return this.http.post<ForgotPasswordModel>(url, model, {
      headers: {
        'lang': this.lang
      }
    });
  }


  ResetPassword(model: ResetPasswordModel): Observable<ResetPasswordModel> {
    let url: string = this.urlPart + "/ResetPassword";
    if (!this.lang)
      this.lang = 'ar';
    return this.http.post<ResetPasswordModel>(url, model, {
      headers: {
        'lang': this.lang
      }
    });
  }
  Register(model: UserModel): Observable<any> {
    let url: string = this.urlPart + "/AdminRegister";
    return this.http.post(url, model);
  }
}

