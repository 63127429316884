import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../DataServices/Authentication.service';
import { AuthModel } from '../../Models/AuthModel';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private router: Router, private authService: AuthenticationService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let authModel: AuthModel;
        if (!this.authService.isAuthenticated()) {
            this.router.navigate(['login'], { queryParams: { returnUrl: state.url } });
            return false;
        }

        return true;
    }
}
